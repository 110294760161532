import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Taurus Man</h4>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus Man </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            
            <p className="playfair text-black text-xl md:text-3xl">Traits</p><br/>
                  Particular and well planned, patient, consistent, sensible,
                  practical, reliable, stubborn, control, sensual,
                  straightforward
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Compatibility:</p> Cancer, Virgo, Capricorn, and Pisces
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Let's take an overview of Taurus men's traits.
                  </p>
                  <br/>
                  <p className="font-bold inline-block text-orange-500">We've all heard the stereotypes:</p> Taurus men are stubborn,
                  strong-headed, and challenging to deal with. They're highly
                  set in their ways and don't like to be pushed around. When
                  they make up their minds about something, they're almost
                  impossible to change. Taurus men love to be in control, both
                  personally and professionally. They don't like to share that
                  power with anyone.
                  <br />
                  <br />
                  The Taurus man is strong and confident but also has a soft
                  side. Taurus men are passionate and affectionate, making their
                  partners feel secure. They're dedicated and loyal, and they'll
                  do anything to make their loved ones happy. Taurus men are
                  known for their stubbornness and determination.
                  <br />
                  <br />
                  They don't compromise. Instead, they focus on finding the
                  solution that will solve the problem. They're also very good
                  at being patient.
                  <br />
                  <br />
                  The Taurus personality is a rare breed. They are stubborn and
                  hard-working, and once they make up their mind, it's almost
                  impossible to change them. Taurus men are go-getters who are
                  determined to succeed in life. They are also loyal and caring
                  and will do anything to help those they love.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Particular and well planned:</p> You may tend to have a more
                  planned and stable life than others. You are more likely to
                  set goals and make decisions than react spontaneously. It
                  would help if you had some control in your life, or it can
                  feel overwhelming.
                  <br />
                  <br />
                  You may have a great deal of appreciation for the material
                  possessions in your life, such as cars and property, and are
                  often very particular about the music that you listen to.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Consistent:</p>Consistent: The Taurus personality is a consistent and
                  determined individual. They have a strong sense of purpose,
                  are committed to their goals, and are reliable. They are also
                  very loyal and protective of those they care about. However,
                  they can be slow to adapt to new situations and ideas, and it
                  can be challenging to change their mind once they have made up
                  their mind.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Reliable</p>Reliable: Taurus is a very reliable and dependable sign,
                  making them great for those who need reliability in their
                  lives. They are patient, loving, and dedicated to their
                  friends and families, often going to great lengths to take
                  care of others. Because they are so loyal and dedicated, they
                  make great partners, as they are always there when you need
                  them.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Stubborn:</p> Taurus is the most stubborn bull of the zodiac. They
                  can be very prideful and take their work and responsibilities
                  very seriously. You are very strong-willed and aren't afraid
                  to show it and will fight for what you believe in. You can be
                  stubborn and can take time to warm up to people, but you can
                  be very faithful to their friends once they do.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Practical:</p> When someone is acting straight toward you,
                  they are honest and upfront with their intentions. They don't
                  dance around the subject, hiding their true feelings or
                  agenda. They speak their mind and don't play games. They are
                  direct and to the point.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Practical:</p> The Taurus personality is practical and
                  down-to-earth. You are a lover of tradition and a hard worker,
                  but you are also a realist. You have a strong sense of justice
                  and fairness and prefer to avoid conflict whenever possible.
                  You are loyal and easy-going but also need time to yourself.
                  <br />
                  <br />
                  A Taurus has a solid practical side. They are great at keeping
                  calm, cool, and collected in the face of any situation. They
                  are the masters of the subtle and the softspoken, who keep
                  things in control and can tole anyone with a straight face.
                  They are the ones who quietly get things done and make the
                  most out of any situation.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Sensuous:</p> Taurus is the fourth sign of the zodiac. It is one
                  of the most apparent signs of the zodiac, as it rules money,
                  wealth, and earthly pleasures. The Taurus is very passionate
                  and sensual. They love the good things in life, so they are
                  very social animals.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    How is Taurus man in love and relationships?
                  </p>
                  <br/>
                  Taurus men are known for being passionate and affectionate.
                  They are naturally romantic and will go to great lengths for
                  their partners, which can be wonderful.
                  <br />
                  <br />
                  However, their need for security and dependence on their
                  partners can be challenging in a long-distance relationship.
                  Understanding what those needs are and being able to meet them
                  is essential for sustaining a long-distance relationship. But
                  once you find the right person to share it with, nothing can
                  compare.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    How To Attract Taurus Men?
                  </p>
                  <br/>
                  Taurus men are a frustrating breed. They're typically great
                  lovers, beautiful partners, and amazing friends. But Taurus
                  men are stubborn, slow to open up, and not always the most
                  affectionate. It can be challenging to plumb the depths of a
                  Taurus man's love, so it's no surprise that many women are
                  attracted to this sign.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Be Patient:</p> You might think that being a born lover would be
                  easy, but it takes a lot of patience to keep a Taurus happy.
                  You need to be patient and give them time to get used to the
                  way you are. This is not the most unmistakable sign to deal
                  with, so be prepared for them to want to control you and your
                  decisions.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Look Your Best:</p> Taurus is a Fixed sign, which means they are
                  set in their ways and are not very adaptable. They are
                  down-to-earth but also care about how things look. As such,
                  they are not very good at keeping up appearances.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Keep it accurate and be direct:</p> Taurus men are the ultimate
                  romantic. They need and appreciate honesty and commitment. If
                  you're looking for a long-term relationship, being
                  straightforward and honest is the way to his heart. However,
                  if you're not being direct and precise, he'll pick up on it
                  and be turned off.
                  <br />
                  <br />

            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
